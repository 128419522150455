const nodePath = require('path');

//Images
const path = {
    Approved: require("../../assets/Images/Approved.png"),
    copy: require("../../assets/Images/copy.png"),
    group: require("../../assets/Images/group.png"),
    grp1: require("../../assets/Images/grp1.png"),
    grp2: require("../../assets/Images/grp2.png"),
    grp3: require("../../assets/Images/grp3.png"),
    kycdapp: require("../../assets/Images/kycdapp.png"),
    illustr: require("../../assets/Images/illustr.png"),
    vshap: require("../../assets/Images/vshap.png"),
    section: require("../../assets/Images/section.png"),
    op1: require("../../assets/Images/op1.png"),
    op2: require("../../assets/Images/op2.png"),
    op3: require("../../assets/Images/op3.png"),
    op4: require("../../assets/Images/op4.png"),
    op5: require("../../assets/Images/op5.png"),
    op6: require("../../assets/Images/op6.png"),
    op7: require("../../assets/Images/op7.png"),
    op8: require("../../assets/Images/op8.png"),
    plusimg: require("../../assets/Images/plusimg.png"),
    fb: require("../../assets/Images/fb.png"),
    pinintrst: require("../../assets/Images/pinintrst.png"),
    reddit: require("../../assets/Images/reddit.png"),
    skype: require("../../assets/Images/skype.png"),
    telegram: require("../../assets/Images/telegram.png"),
    twiitr: require("../../assets/Images/twiitr.png"),
    linkdin: require("../../assets/Images/linkdin.png"),
    Whatsapp: require("../../assets/Images/Whatsapp.png"),
    kycdapwhite: require("../../assets/Images/kycdapwhite.png"),
    utubwht: require("../../assets/Images/utubwht.png"),
    whIn: require("../../assets/Images/whIn.png"),
    whitetwt: require("../../assets/Images/whitetwt.png"),
    whtinsta: require("../../assets/Images/whtinsta.png"),
    grpfb: require("../../assets/Images/grpfb.png"),
    Unacceptable: require("../../assets/Images/Unacceptable.png"),
    Unreliable: require("../../assets/Images/Unreliable.png"),
    VerySlow: require("../../assets/Images/VerySlow.png"),
    expensive: require("../../assets/Images/expensive.png"),
    braces: require("../../assets/Images/braces.svg"),
    wave: require("../../assets/Images/layout/wave-11.svg"),
    esn: require("../../assets/Images/logo/esn-logo.png"),
    swapperwall: require("../../assets/Images/logo/swapperswall.png"),
    timeally: require("../../assets/Images/logo/timeally.png"),
    timeallyclub: require("../../assets/Images/logo/timeallyclub.png"),
    daap: require("../../assets/Images/logo/1daap.png"),
    eraswapwallet: require("../../assets/Images/logo/eraswapwallet.png"),
    timeswappers: require("../../assets/Images/logo/timeswappers.png"),
    dayswappers: require("../../assets/Images/logo/dayswappers.png"),
    buzcafe: require("../../assets/Images/logo/buzcafe.png"),
    eraswapacademy: require("../../assets/Images/logo/academy.png"),
    betdeex: require("../../assets/Images/logo/betdeex-logo.png"),
    computeex: require("../../assets/Images/logo/comp-lb.png"),
    computeexmultiexchange: require("../../assets/Images/logo/computeex.png"),
    faithminus: require("../../assets/Images/logo/faithminus.png"),
    vof: require("../../assets/Images/logo/vof.png"),
    certidapp: require("../../assets/Images/logo/certidapp.png"),
    kycdapp: require("../../assets/Images/logo/kycdapp.png"),
    bookingdapp: require("../../assets/Images/logo/booking.png"),
    charitydapp: require("../../assets/Images/logo/charitydapp.png"),
    rentingdapp: require("../../assets/Images/logo/rentingdapp.png"),
    curedapp: require("../../assets/Images/logo/curedapp.png"),
    dateswappers: require("../../assets/Images/logo/datesswappers.png"),
    coupondapp: require("../../assets/Images/logo/coupondapp.png"),
    recyclingdapp: require("../../assets/Images/logo/recyclingdapp.png"),
    poolingdapp: require("../../assets/Images/logo/poolingdapp.png"),
    eraswap: require('../../assets/Images/S_LIFE.png'),
    workinprogress: require('../../assets/Images/workinprogress.png'),
    addressproof: require('../../assets/Images/addres-proof.png'),
    idproof: require('../../assets/Images/id-proof.png'),
    selfiproof: require('../../assets/Images/selfi-proof.png'),
    decentralized: require('../../assets/Images/decentralized.png'),
    idProof: require('../../assets/Images/id-proof.png'),
    addressProof: require('../../assets/Images/address.png'),
    selfieProof: require('../../assets/Images/selfie.jpg'),
    flags: require('../../assets/Images/flags.png'),
    videoupload: require('../../assets/Images/videoupload.png'),
    uploadimage: require('../../assets/Images/uploadimage.png'),
    uploaddoc: require('../../assets/Images/upload-doc.png'),
};

export default {
    path
}